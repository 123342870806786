<template>
  <!--
        For medium devices
        Navigation is visible  down < 1091px and up > 576px width device
    -->
  <div class="navbar__md d-none d-sm-block d-md-block d-lg-none">
    <nav class="navbar fixed-top">
      <a href="javascript:void(0)" onclick="menuToggle(1)" class="nav-link">
        <img src="/static/images/icons/menu.png" alt="notifications" class="img-fluid ic">
      </a>
      <ul class="navbar-nav ml-auto flex-row  align-items-center">
        <li class="nav-item">
          <a href="#" class="nav-link">
            <img src="/static/images/icons/search.png" alt="search" class="img-fluid ic">
          </a>
        </li>
        <li class="nav-item">
          <!-- If there is no notification use this image -->
<!--          <notifications></notifications>-->
<!--          <a href="#" class="nav-link">-->
<!--              <img src="/static/images/icons/notifications.png" alt="notifications" class="img-fluid ic">-->
<!--          </a>-->
        </li>
        <li class="nav-item d-flex align-items-center">
          <language-switcher></language-switcher>
        </li>
        <li class="nav-item">
          <a class="nav-link text-nowrap d-flex align-items-center" href="#" id="navbarDropdown_2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="circle-border">
              <div class="circle ">
                <img v-if="studentPicture" :src="studentPicture" alt="" class="img-fluid">
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-md-center dropdown-menu-profile" aria-labelledby="navbarDropdown_2">
            <router-link class="dropdown-item" to="/profile">{{ $t('profile.title') }}</router-link>
            <router-link class="dropdown-item" to="/logout">{{ $t('logout') }}</router-link>
          </div>
        </li>
      </ul>
      <div id="myNavmd" class="navigation-overlay">
        <div class="text-left mb-5">
          <a href="javascript:void(0)" class="closebtn" onclick="menuToggle(0)">
            <img src="/static/images/icons/close.png" alt="">
          </a>
        </div>
        <div class="overlay-content d-flex ">
          <ul class="navbar-nav mb-auto " >
            <li :class="navItem.items && navItem.items.length > 0 ? 'nav-item dropdown' : 'nav-item'"
                v-for="(navItem, i) of headerMenuTree">
              <router-link v-if="navItem.items && navItem.items.length > 0"
                           to="#"
                           :id="'navbarDropdown_md-' + i"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                           class="nav-link text-nowrap" >
                <img :src="navItem.icon" alt="" class="img-fluid pre-icon pr-4">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
              </router-link>
              <router-link v-else
                           :to="navItem.link"
                           class="nav-link text-nowrap" >
                <img :src="navItem.icon" alt="" class="img-fluid pre-icon pr-4">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
              </router-link>
              <div v-if="navItem.items && navItem.items.length > 0" class="dropdown-menu" :aria-labelledby="'navbarDropdown_md-' + i">
                <span v-for="navSubItem of navItem.items">
                  <router-link
                    v-if="navSubItem.is_oldug_redirect && navSubItem.is_oldug_redirect == 0"
                    class="dropdown-item"
                    :to="navSubItem.link">
                    {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                  </router-link>
                  <a v-else
                     target="_blank"
                     class="dropdown-item"
                     :href="'https://old.my.ug.edu.ge/login.php?redirect=' + navSubItem.link + '&token=' + token">
                    {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                  </a>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import LanguageSwitcher from '../language-switcher';
  import Notifications from '../notifications';
  import { mapGetters } from 'vuex';
  export default {
    name: 'nav-header-md',
    components: { LanguageSwitcher, Notifications },
    props: ['headerMenuTree', 'studentPicture'],

    computed: {
      ...mapGetters({
        token: 'auth/token'
      })
    }
  };
</script>
