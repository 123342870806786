<template>
  <!-- NAVIGATION start -->
  <!--
      For large devices
      Navigation is visible only up > 1091px width device
  -->
  <div class="d-none d-lg-block">
    <nav class="navbar navbar-expand-md navbar__lg  fixed-top ">
      <div class="sm-w-100 d-flex order-1 order-md-2">
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav ml-auto flex-row align-items-center">
          <li class="nav-item d-flex align-items-center order-2 order-md-1">
            <language-switcher></language-switcher>
          </li>
          <li class="nav-item order-1 order-md-2 ">
            <notifications></notifications>
          </li>
          <li class="nav-item dropdown order-3 ">
            <a class="nav-link text-nowrap d-flex align-items-center" href="#" id="navbarDropdown_2" role="button" data-display="static" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="circle-border">
                <div class="circle">
                  <img v-if="studentPicture" :src="studentPicture" alt="" class="img-fluid">
                  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc" class="">
                    <tb-skeleton :aspect-ratio="0.05"></tb-skeleton>
                  </skeleton>
                </div>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-profile" aria-labelledby="navbarDropdown_2">
              <router-link class="dropdown-item" to="/profile">{{ $t('profile.title') }}</router-link>
              <router-link class="dropdown-item" to="/logout">{{ $t('logout') }}</router-link>
            </div>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse order-2 order-md-1 navbarSupportedContent" id="navbarSupportedContent">
        <ul class="navbar-nav" >
          <li :class="navItem.items && navItem.items.length > 0 ? 'nav-item dropdown' : 'nav-item'" v-for="(navItem, i) of headerMenuTree">
            <router-link v-if="navItem.items && navItem.items.length > 0"
                         to="#"
                         :id="'navbarDropdown_lg-' + i"
                         role="button"
                         data-toggle="dropdown"
                         aria-haspopup="true"
                         aria-expanded="false"
                         class="nav-link text-nowrap" >
              <img :src="navItem.icon" alt="" class="img-fluid pre-icon">
              {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
            </router-link>
            <router-link v-else
                         :to="navItem.link"
                         class="nav-link text-nowrap" >
              <img :src="navItem.icon" alt="" class="img-fluid pre-icon">
              {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
            </router-link>
            <div v-if="navItem.items && navItem.items.length > 0" class="dropdown-menu" :aria-labelledby="'navbarDropdown_lg-' + i">
              <span v-for="navSubItem of navItem.items">
                <router-link
                  v-if="navSubItem.is_oldug_redirect == 0"
                  class="dropdown-item"
                  :to="navSubItem.link">
                  {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                </router-link>
                <a v-else :href="'https://old.my.ug.edu.ge/login.php?redirect=' + navSubItem.link + '&token=' + token" target="_blank" class="dropdown-item">
                  {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                </a>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
  import LanguageSwitcher from '../language-switcher';
  import { mapGetters } from 'vuex';
  import Notifications from '../notifications';
  export default {
    name: 'nav-header-lg',
    components: { Notifications, LanguageSwitcher },
    props: ['headerMenuTree', 'studentPicture'],

    computed: {
      ...mapGetters({
        token: 'auth/token'
      })
    }
  };
</script>
<style scoped>
  .dropdown:hover>.dropdown-menu {
    display: block;
  }
</style>
