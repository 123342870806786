<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc">
    <div class="row">
      <div class="col-3 col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
      <div class="col-3 col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
      <div class="col-3 col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
      <div class="col-3 col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
      <div class="d-none d-lg-flex col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
      <div class="d-none d-lg-flex col-lg-2">
        <tb-skeleton :aspect-ratio="0.3"></tb-skeleton>
      </div>
    </div>
  </skeleton>
</template>

<script>
  export default {
    name: 'sk-navbar-header',
  };
</script>
