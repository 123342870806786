<template>
  <div>
    <nav-header-lg :student-picture="studentPicture" :header-menu-tree="headerMenuTree"></nav-header-lg>
    <nav-header-md :student-picture="studentPicture" :header-menu-tree="headerMenuTree"></nav-header-md>
    <nav-header-sm :student-picture="studentPicture" :header-menu-tree="headerMenuTree"></nav-header-sm>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Notifications from '../notifications';
  import LanguageSwitcher from '../language-switcher';
  import SkNavbarHeader from '../skeletons/sk-navbar-header';
  import NavHeaderLg from '../navbar/nav-header-lg';
  import NavHeaderMd from '../navbar/nav-header-md';
  import NavHeaderSm from '../navbar/nav-header-sm';
  import i18n from '../../i18n';

  export default {
    name: 'nav-header',
    components: {
      Notifications, LanguageSwitcher, SkNavbarHeader, NavHeaderLg, NavHeaderMd, NavHeaderSm
    },

    computed: {
      ...mapGetters('navbar', [
        'headerMenuTree',
      ]),
      ...mapGetters('auth', [
        'studentPicture',
      ]),
    },

    methods: {
      setLocale(locale) {
        i18n.locale = locale;
        localStorage.setItem('locale', locale);
        this.$emit('changed-locale', locale);
      },
    },
  };
</script>
