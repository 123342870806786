<template>
  <!--
        For small devices
        Navigation is visible only down < 576px width device
    -->
  <nav class="navbar  d-flex align-items-center navbar__sm  fixed-top  d-block d-sm-none">
    <router-link class="navbar-brand" to="/">
      <img src="/static/images/logo-red.png" alt="" class="img-fluid">
    </router-link>
    <ul class="navbar-nav ml-auto flex-row  align-items-center">
      <li class="nav-item ">
        <a href="javascript:void(0)" onclick="menuToggle(1)" class="nav-link">
          <img src="/static/images/icons/menu.png" alt="notifications" class="img-fluid ic" style="height: 19px !important;">
        </a>
      </li>
      <li class="nav-item ">
        <a href="#" class="nav-link">
          <img src="/static/images/icons/search.png" alt="search" class="img-fluid ic">
        </a>
      </li>
       <li class="nav-item">
         <notifications></notifications>
<!--         <a href="#" class="nav-link position-relative">-->
<!--           <span class="pulse"></span>-->
<!--           <img src="/static/images/icons/notification-recieved.png" alt="notifications" class="img-fluid ic position-relative">-->
<!--         </a>-->
       </li>
      <li class="nav-item">
        <a class="nav-link text-nowrap d-flex align-items-center" href="#" id="navbarDropdown_2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="circle-border">
            <div class="circle ">
              <img :src="studentPicture" alt="" class="img-fluid">
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-md-center dropdown-menu-profile" aria-labelledby="navbarDropdown_2">
          <router-link class="dropdown-item" to="/profile">{{ $t('profile.title') }}</router-link>
          <router-link class="dropdown-item" to="/logout">{{ $t('logout') }}</router-link>
        </div>
      </li>
    </ul>
    <div id="myNav" class="navigation-overlay">
      <div class="text-right">
        <a href="javascript:void(0)" class="closebtn" onclick="menuToggle(0)">
          <img src="/static/images/icons/close.png" alt="close button">
        </a>
      </div>
      <div class="overlay-content d-flex justify-content-end flex-column h-100 ">
        <ul class="navbar-nav mb-auto " >
          <li :class="navItem.items && navItem.items.length > 0 ? 'nav-item dropdown' : 'nav-item'" v-for="(navItem, i) of headerMenuTree">
            <router-link v-if="navItem.items && navItem.items.length > 0"
                         to="#"
                         :id="'navbarDropdown_md-' + i"
                         role="button"
                         data-toggle="dropdown"
                         aria-haspopup="true"
                         aria-expanded="false"
                         class="nav-link text-nowrap" >
              {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
              <img :src="navItem.icon" alt="" class="img-fluid pre-icon pl-4">
            </router-link>
            <router-link v-else
                         :to="navItem.link"
                         class="nav-link text-nowrap" >
              {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
              <img :src="navItem.icon" alt="" class="img-fluid pre-icon pl-4">
            </router-link>
            <div v-if="navItem.items && navItem.items.length > 0" class="dropdown-menu" :aria-labelledby="'navbarDropdown_md-' + i">
              <span v-for="navSubItem of navItem.items">
                  <router-link
                    v-if="navSubItem.is_oldug_redirect == 0"
                    class="dropdown-item"
                    :to="navSubItem.link">
                    {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                  </router-link>
                  <a v-else
                     target="_blank"
                     class="dropdown-item"
                     :href="'https://old.my.ug.edu.ge/login.php?redirect=' + navSubItem.link + '&token=' + token">
                    {{ helpers.getLocalizedField(navSubItem, 'name', 'name_eng') }}
                  </a>
                </span>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link text-nowrap">
              <img @click="setLocale('ka')" src="/static/images/geo.svg" alt="" class="img-fluid flag-icon pl-4">
              <img @click="setLocale('en')" src="/static/images/eng.svg" alt="" class="img-fluid flag-icon pl-4">
            </a>
          </li>
        </ul>
        <div class="ug-logo mx-auto">
          <img src="/static/images/logo-red.png" alt="ug-logo" class="img-fluid">
        </div>
      </div>
    </div>
  </nav>
  <!-- NAVIGATION end -->
</template>

<script>
  import i18n from '../../i18n';
  import Notifications from '../notifications';
  import { mapGetters } from 'vuex';

  export default {
    name: 'nav-header-sm',
    components: {Notifications},
    props: ['headerMenuTree', 'studentPicture'],

    computed: {
      ...mapGetters({
        token: 'auth/token'
      })
    },

    methods: {
      setLocale(locale) {
        this.$store.dispatch('language/setLocale', locale);
        this.$emit('changed-locale', locale);
      },
    }
  };
</script>
<style scoped>
  .navbar .nav-link:focus{
    color: #cb2026;
  }

  .flag-icon {
    width: 38px;
    height: 38px;
  }
</style>
