<template>
  <a :href="link" @click="handleClick" :class="[{unread: !item.seen_at}, 'dropdown-item', 'd-flex']">
    <div class="icon">
      <img :src="item.icon">
    </div>
    <div>
      <div :class="{'font-weight-bold': !item.seen_at}" v-html="item.text"></div>
      <div class="small text-muted">{{ helpers.getDateDifference(item.created_at) }}</div>
    </div>
  </a>
</template>

<script>
  export default {
    name: 'notification-item',

    props: ['item'],

    computed: {
      link() {
        return this.item.link || null;
      }
    },

    methods: {
      handleClick($event) {
        $event.stopPropagation();
        $event.preventDefault();
        this.$emit('seen', this.item);
      }
    }
  };
</script>

<style scoped>
  .dropdown-item {
    border-radius: 0 !important;
  }

  .dropdown-item:hover, .dropdown-item.unread {
    font-family: inherit !important;
    background-color: rgba(192, 192, 192, 0.1);
  }

  .dropdown-item .icon {
    display: block;
    margin-right: 1rem;
    padding: 1rem;
  }

  .dropdown-item .icon img {
    width: 36px;
  }
</style>
