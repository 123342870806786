<template>
  <div class="dropdown header-notify pl-4">
    <a @click="loadNotifications" class="nav-link position-relative cursor-pointer" data-toggle="dropdown">
      <span v-if="newNotifications > 0">
        <span class="pulse"></span>
        <img src="/static/images/icons/notification-recieved.png" alt="notifications" class="img-fluid ic position-relative">
      </span>
      <span v-else>
        <img src="/static/images/icons/notifications.png" alt="notifications" class="img-fluid ic position-relative">
      </span>
    </a>
<!--    <a class="nav-link icon p-0" data-toggle="dropdown">-->
<!--      <svg class="header-icon" x="1008" y="1248" viewBox="0 0 24 24" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path opacity=".3" d="M12 6.5c-2.49 0-4 2.02-4 4.5v6h8v-6c0-2.48-1.51-4.5-4-4.5z"></path><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-11c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-2 6H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zM7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2a8.445 8.445 0 013.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43a8.495 8.495 0 013.54 6.42z"></path></svg>-->
<!--      <span class="pulse "></span>-->
<!--    </a>-->
    <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated">
      <sk-list v-if="loading" :height="0.2" :items-count="4"></sk-list>
      <div v-else class="dropdown-menu-items">
        <div v-if="notifications.length === 0" class="no-notifications">
          {{ $t('notifications.not-found') }}
        </div>
        <notification-item
          :key="$index"
          v-for="(notification, $index) of notifications"
          :item="notification"
          @seen="setSeen"
        ></notification-item>
      </div>
      <div class=" text-center p-2 border-top">
        <a @click="seeAll()" href="#">{{ $t('notifications.mark-all-as-read') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import NotificationItem from './notification-item';
  import { mapGetters } from 'vuex';
  import SkList from './skeletons/sk-list';

  export default {
    name: 'notifications',
    components: { SkList, NotificationItem },

    data() {
      return {
        page: 1,
        per_page: 20
      }
    },

    computed: {
      ...mapGetters({
        notifications: 'notifications/notifications',
        loading: 'notifications/loading',
        newNotifications: 'notifications/newNotifications'
      })
    },

    methods: {
      setSeen(notification) {
        if(!notification.seen_at) {
          this.$store.dispatch('notifications/seen', notification.notificationID)
          .then(() => {
            if(notification.link) window.location.href = notification.link;
          });
        } else if(notification.link) window.location.href = notification.link;
      },

      seeAll() {
        this.$store.dispatch('notifications/seeAll')
        .then(() => {
          this.$store.dispatch('notifications/loadNotifications');
          this.$store.dispatch('notifications/loadCounts');
        });
      },

      loadNotifications() {
        this.$store.dispatch('notifications/loadNotifications', {
          page: this.page, per_page: this.per_page
        });
      }
    }
  };
</script>

<style scoped>
  .no-notifications {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    min-height: 56px;
  }
  .dropdown-menu {
    padding: 1.4rem 0 0 0;
    min-width: 26.5rem;
  }
  .dropdown-menu-items {
    max-height: 300px;
    overflow-y: auto;
  }
  .dropdown-menu-items::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .dropdown-menu-items::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 5px;
    -webkit-box-shadow: unset;
  }
  .dropdown-menu-items::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2;
    -webkit-box-shadow: unset;
  }
  .dropdown-menu-items::-webkit-scrollbar-track {
    padding-left: 1rem;
    background: #f2f2f2;
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 0;
  }
</style>
