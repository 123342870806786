<template>
  <h2 class="text-center mt-5 mt-sm-0">{{ title }}</h2>
</template>

<script>
  export default {
    props: ['title'],
    name: 'page-title',
  };
</script>
