<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc">
    <div class="row">
      <div class="col-12">
        <tb-skeleton :aspect-ratio="1" shape="circle">
        </tb-skeleton>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12" style="padding-left: 10px;margin-top: 5px">
            <tb-skeleton :aspect-ratio="0.1"></tb-skeleton>
          </div>
          <div class="col-12" style="padding-left: 10px;margin-top: 10px">
            <tb-skeleton :aspect-ratio="0.05"></tb-skeleton>
          </div>
          <div class="col-12" style="padding-left: 10px;margin-top: 10px">
            <tb-skeleton :aspect-ratio="0.05"></tb-skeleton>
          </div>
        </div>
      </div>
    </div>
  </skeleton>
</template>

<script>
  export default {
    name: 'sk-sidebar',
  };
</script>
