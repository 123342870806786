<template>

      <!-- Breadcrump -->
      <div class="breadcrumb-container  mb-3">
        <nav aria-label="breadcrumb d-none d-sm-none d-md-block">
          <ol class="breadcrumb  ml-auto ">
            <li :class="['breadcrumb-item', { active: item.isActive } ]" v-for="item of items">
              <router-link :to="item.link">
                {{ item.title }}
              </router-link>
            </li>
          </ol>
        </nav>
      </div>
  
</template>

<script>
  export default {
    name: 'breadcrumb',
    props: ['items'],
  };
</script>

<style scoped>

</style>
