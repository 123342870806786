<template>
  <div>
    <!-- SIDEBAR start -->
    <!-- sidebar is visible only up > 576px width device -->
    <aside class="col-12 col-sm-3 col-md-2 col-lg-2 position-fixed sd-bg px-sm-0 px-md-0 px-lg-1 px-xl-3 d-none d-sm-block">
      <div class="sidebar flex-nowrap overflow-auto px-0 d-flex align-items-center flex-column h-100 px-md-0 px-lg-1 px-xl-4">
        <div class="mb-auto mt-5 ">
          <!-- Profile Image -->
          
          <sk-sidebar v-if="!user || !studentPicture"></sk-sidebar>
          <div v-else class="d-flex justify-content-center">
            <div class="circle-border ">
              <div class="circle">
                <img :src="studentPicture" alt="profile-image" class="img-fluid">
              </div>
            </div>
          </div>
          <!-- Profile Information -->
          <div class="text-center mt-4 mb-md-5 mb-lg-0">
            <p class="f-p1 mb-2">{{ helpers.getLocalizedField(user, 'fullName') }}</p>
            <p class="f-p2">{{ helpers.getLocalizedField(user, 'speciality') }}</p>
            <p class="f-p2">{{ helpers.getLocalizedField(user, 'studentStatus') }}</p>
          </div>
          <!-- SideBar Menu -->

 


          <sk-navbar-sidebar v-if="!sidebarNavItems || sidebarNavItems.length === 0"></sk-navbar-sidebar>
          <ul v-else class="nav flex-column">
            <li v-if="user.currentExam==0" class="nav-item" v-for="navItem of sidebarNavItems">
              <router-link v-if="navItem.is_oldug_redirect == 0"
                           :id="navItem.htmlID ? navItem.htmlID : ''"
                           class="nav-link d-flex align-items-baseline justify-content-start"
                           :to="navItem.link">
                <img :src="navItem.icon" alt="" class="img-fluid">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
                <span v-if="unreadConversations > 0 && navItem.htmlID === 'conversations'" class="ml-1 badge-danger badge-pill">{{ unreadConversations }}</span>
              </router-link>
              <a v-else
                 :id="navItem.htmlID ? navItem.htmlID : ''"
                 class="nav-link d-flex align-items-baseline justify-content-start"
                 target="_blank" :href="'https://old.my.ug.edu.ge/login.php?redirect=' + navItem.link + '&token=' + token">
                <img :src="navItem.icon" alt="" class="img-fluid">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
                <span v-if="unreadConversations > 0 && navItem.htmlID === 'conversations'" class="ml-1 badge-danger badge-pill">{{ unreadConversations }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class=" d-flex justify-content-center ug-logo">
          <router-link to="/">
            <img src="/static/images/logo-red.png" alt="ug-logo" class="img-fluid">
          </router-link>
        </div>
      </div>
    </aside>
    <!-- SIDEBAR end -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skSidebar from '../../components/skeletons/sk-sidebar';
import skNavbarSidebar from '../../components/skeletons/sk-navbar-sidebar';

export default {
  name: 'sidebar',

  components: {
    skSidebar, skNavbarSidebar,
  },

  computed: {
    ...mapGetters('navbar', [
      'sidebarNavItems',
    ]),
    ...mapGetters('auth', [
      'studentPicture',
      'user',
    ]),
    ...mapGetters('conversation', [
      'unreadConversations',
    ]),
    ...mapGetters('auth', [
      'token'
    ])
  },
};
</script>
